import request from '@/utils/request';

interface LoginParams {
  mobile: string;
  password: string;
  imei: string;
  openId?: string;
}

interface LoginResponse {
  code: string;
  data: {
    accessToken: string;
    tokenType: string;
    refreshToken: string;
    expires: number;
  };
  msg: string;
}

export function login(data: LoginParams) {
  const loginData = {
    mobile: data.mobile.trim(),
    password: data.password.trim(),
    imei: data.imei.trim(),
    ...(data.openId ? { openId: data.openId.trim() } : {})
  };

  return request<LoginResponse>({
    url: '/api/v2/auth/login',
    method: 'post',
    data: loginData
  });
}

export function logout() {
  return request({
    url: '/api/v2/auth/logout',
    method: 'delete'
  })
}
