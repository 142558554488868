// src/router/index.ts
import { createRouter, createWebHistory } from 'vue-router'
import MainLayout from '@/views/layout/MainLayout.vue'

const routes = [
  {
    path: '/login',
    component: () => import('@/views/login/LoginPage.vue'),
    meta: { title: '登录' }
  },
  {
    path: '/',
    component: MainLayout,
    children: [
      {
        path: '',
        redirect: '/home'  // 修改默认重定向到首页
      },
      {
        path: '/home',
        component: () => import('@/views/home/homePage.vue'),
        meta: { title: '首页' }
      },
      {
        path: '/products',
        component: () => import('@/views/product/productPage.vue'),
        meta: { title: '产品应用' }
      },
      {
        path: '/support',
        component: () => import('@/views/support/supportPage.vue'),
        meta: { title: '技术支持' }
      },
      {
        path: '/gongdan',
        component: () => import('@/views/gongdan/gongdanPage.vue'),
        meta: { 
          title: '工单',
          requiresAuth: true
        }
      },
      {
        path: '/about',
        component: () => import('@/views/about/aboutPage.vue'),
        meta: { title: '关于我们' }
      }
    ]
  },
  {
    path: '/404',
    component: () => import('@/views/error/404.vue'),
    meta: { title: '404' }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// 全局前置守卫
router.beforeEach(async (to, from, next) => {
  // 设置页面标题
  document.title = to.meta.title ? `${to.meta.title}` : '默认标题'

  // 只有访问工单页面时才检查登录状态
  if (to.path === '/gongdan') {
    const token = localStorage.getItem('token')
    if (!token) {
      next({
        path: '/login',
        query: { redirect: '/gongdan' }
      })
      return
    }
  }
  
  // 如果已登录且访问登录页，重定向到工单页
  if (to.path === '/login' && localStorage.getItem('token')) {
    next('/gongdan')
    return
  }

  // 其他所有页面都允许自由访问
  next()
})

export default router