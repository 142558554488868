<template>
  <el-container class="layout-container">
    <!-- 头部导航 -->
    <el-header class="main-header">
      <div class="header-content">
        <div class="logo">
          <img :src="require('@/assets/titlelog.jpg')" alt="得圣" @click="router.push('/')" />
        </div>

        <el-menu
            mode="horizontal"
            :default-active="activeMenu"
            class="nav-menu"
            :router="true"
        >
          <el-menu-item
              v-for="item in filteredNavMenus"
              :key="item.path"
              :index="item.path"
              @click="handleNavClick(item.path)"
          >
            {{ item.name }}
          </el-menu-item>
        </el-menu>

        <div class="download-container">
          <el-button class="download-btn" @click="dialogVisible = true">
            下载手机APP
          </el-button>
          <el-button 
            v-if="isGongdanPage" 
            class="logout-btn" 
            type="text" 
            @click="handleLogout"
          >
            退出登录
          </el-button>
        </div>
      </div>
    </el-header>

    <!-- 主要内容区域 -->
    <el-main class="main-content">
      <router-view></router-view>
    </el-main>

    <!-- Footer -->
    <footer class="footer">
      <div class="footer-content">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="contact-info">
              <h1 class="footer-title">联系我们</h1>
              <p class="footer-text">地址：北京市大兴区星光影视园4号楼6层</p>
              <p class="footer-text">电话：400-106-6576</p>
              <p class="footer-text">邮箱: postmaster@dstckj.com</p>
            </div>
          </el-col>
          <el-col :span="4">
            <el-divider direction="vertical" border-style="dashed" class="footer-divider" />
          </el-col>
          <el-col :span="8">
            <div class="qr-code">
              <img :src="require('@/assets/wxgz.png')" class="qr-image" />
            </div>
          </el-col>
        </el-row>

        <div class="copyright">
          <p>Copyright  北京东圣天成科技有限公司 &emsp;&emsp;&emsp;   京ICP备2024060857号-3</p>
        </div>
      </div>
    </footer>

    <!-- QR Code Dialog -->
    <el-dialog
        v-model="dialogVisible"
        title="二维码下载"
        width="250"
        :before-close="handleClose"
        center
        class="qr-dialog"
    >
      <img :src="require('@/assets/wxgz.png')" class="dialog-qr" />
    </el-dialog>

  </el-container>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { logout } from '@/api/auth'

const router = useRouter()
const route = useRoute()

// 判断当前是否在工单页面
const isGongdanRoute = computed(() => route.path.startsWith('/gongdan'))

// 判断是否是工单页面
const isGongdanPage = computed(() => {
  return route.path.includes('/gongdan')
})

// 导航菜单数据
const navMenus = [
  {
    path: '/home',
    name: '首页'
  },
  {
    path: '/products',
    name: '产品应用'
  },
  {
    path: '/support',
    name: '技术支持'
  },
  {
    path: '/about',
    name: '关于我们'
  },
  {
    path: '/gongdan',
    name: '工单管理'
  }
]

// 根据路由筛选显示的菜单
const filteredNavMenus = computed(() => {
  if (isGongdanRoute.value) {
    return navMenus.filter(menu => menu.path === '/gongdan')
  }
  return navMenus.filter(menu => menu.path !== '/gongdan')
})

// 计算当前激活的菜单
const activeMenu = computed(() => {
  if (isGongdanRoute.value) {
    return '/gongdan'
  }
  return route.path
})

// 控制下载二维码弹窗
const dialogVisible = ref(false)

const handleClose = () => {
  dialogVisible.value = false
}

// 处理导航点击
const handleNavClick = (path: string) => {
  router.push(path)
}

// 处理退出登录
const handleLogout = async () => {
  try {
    await logout()
    localStorage.removeItem('token')
    router.push('/login')
  } catch (error) {
    console.error('退出登录失败:', error)
  }
}
</script>

<style scoped>
.layout-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* 头部样式 */
.main-header {
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: white;
  padding: 0;
  height: 60px;
}

.header-content {
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.logo {
  cursor: pointer;
  min-width: 125px;
}

.logo img {
  height: 40px;
  width: auto;
}

.nav-menu {
  flex: 1;
  justify-content: center;
  border: none;
  margin: 0 20px;
}

.nav-menu :deep(.el-menu-item) {
  font-size: 16px;
  height: 60px;
  line-height: 60px;
}

.nav-menu :deep(.el-menu-item.is-active) {
  color: #409EFF;
  border-bottom: 2px solid #409EFF;
}

.nav-menu :deep(.el-menu-item:hover) {
  color: #409EFF;
}

.download-container {
  display: flex;
  align-items: center;
  gap: 16px;
  min-width: 125px;
  justify-content: flex-end;
}

.download-btn {
  background-image: url('@/assets/btn_bg1.jpg');
  background-size: cover;
  background-position: center;
  border: none;
  color: white;
  padding: 10px 20px;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.download-btn:hover {
  opacity: 0.9;
  transform: translateY(-2px);
}

.logout-btn {
  color: #666;
}

.logout-btn:hover {
  color: #409EFF;
}

/* 主内容区域 */
.main-content {
  margin-top: 60px; /* 与header高度相同 */
  min-height: calc(100vh - 60px);
}

:deep(.el-main) {
  padding: 0;
}

/* Footer Styles */
.footer {
  background: #f5f5f5;
  padding: 40px 0;
  margin-top: auto;
  background-image: url('@/assets/conterbg3.png');
  background-size: cover;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.contact-info {
  padding: 0 20px;
}

.footer-title {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 20px;
}

.footer-text {
  color: #666;
  margin: 10px 0;
  line-height: 1.6;
}

.footer-divider {
  height: 200px;
  margin-left: 50px;
}

.qr-code {
  display: flex;
  justify-content: center;
  align-items: center;
}

.qr-image {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.copyright {
  text-align: center;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #ddd;
  color: #999;
  font-size: 0.9rem;
}

/* Dialog Styles */
.qr-dialog {
  text-align: center;
}

.dialog-qr {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

/* 响应式调整 */
@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    height: auto;
    padding: 10px;
  }

  .nav-menu {
    width: 100%;
    margin: 10px 0;
  }

  .download-container {
    margin-top: 10px;
    width: 100%;
    justify-content: center;
  }

  .footer-divider {
    display: none;
  }

  .footer-qr {
    margin-top: 30px;
  }
}
</style>