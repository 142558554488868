import axios from 'axios'
import { ElMessage } from 'element-plus'
import router from '@/router'

const service = axios.create({
  baseURL: 'http://www.bjdskj.com:8283',
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json'
  }
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers['Authorization'] = `${token}`
      config.headers['imei'] = '039901910695714'
      config.headers['client_type'] = 0
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    // 添加调试日志
    console.log('Response:', res)
    
    if (res.code === '00000') {
      return res
    }
    // 处理token过期
    if (res.code === 'A0230' || res.code === '401' || res.code === 401) {
      ElMessage.error('登录已过期，请重新登录')
      localStorage.removeItem('token')
      // 记录当前路由
      const currentPath = router.currentRoute.value.fullPath
      if (currentPath !== '/login') {
        router.push({
          path: '/login',
          query: { redirect: currentPath }
        })
      } else {
        router.push('/login')
      }
      return Promise.reject(res)
    }
    ElMessage.error(res.msg || '请求失败')
    return Promise.reject(res)
  },
  error => {
    // 添加错误调试日志
    console.error('Request Error:', error.response?.data || error)
    
    if (error.response) {
      switch (error.response.status) {
        case 401:
          ElMessage.error('登录已过期，请重新登录')
          localStorage.removeItem('token')
          router.push('/login')
          break
        case 403:
          ElMessage.error('没有权限访问')
          break
        case 404:
          ElMessage.error('请求的资源不存在')
          break
        case 500:
          ElMessage.error('服务器错误')
          break
        default:
          ElMessage.error(error.response.data?.msg || error.message || '请求失败')
      }
    } else {
      ElMessage.error('网络错误，请检查网络连接')
    }
    return Promise.reject(error)
  }
)

export default service
